/* eslint-disable */
// TODO
import 'zone.js/dist/zone';

try {
  let opts = Object.defineProperty({}, 'passive', {
    get: function () {
      if (!(<any>self).CCF) {
        (<any>self).CCF = {};
      }
      (<any>self).CCF.supportsPassiveListeners = true; //I don't want to be importing models here
    },
  });
  addEventListener('testPassive', null!, opts);
  removeEventListener('testPassive', null!, opts);
} catch (e) {
  //Do nothing
}

//No console.debug on production
if (window.console && /^charlieschocolatefactory\.com$/.test(window.location.host)) {
  window.console.debug = function (...args: any[]) {
    //Do nothing
  };
}
